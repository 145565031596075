function userCostNormalize(userData) {
    const { last_week_cost, name, total_cost } = userData;
    return {
        name,
        lastWeekCost: last_week_cost,
        totalCost: total_cost,
    };
}
function userCostArrayNormalize(userCostArray) {
    return userCostArray.map(userCostNormalize);
}
export { userCostArrayNormalize };
